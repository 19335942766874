// News

.news {
    .card-group-element {
        margin: -8px;
    }
}

.news-list-view {
    margin-top: 1.5rem;

    .card-group-element-item {
        .card-body {
            .card-title {
                line-height: 1.3;
            }

            &::before {
                top: 1rem;

                @include media-breakpoint-up(xl) {
                    top: 1.3125rem;
                }
            }
        }
    }
}

.w4-news-list {
    a {
        .w4-read-more {
            display: inline-block;
            font-size: 0.938rem;

            &::after {
                content: ' ';
                display: block;
                width: 0;
                border-bottom: 1px solid $primary;
                transition: width 1s;
            }
        }
        &:hover {
            .w4-read-more::after {
                width: 100%;
            }
        }
    }
}

.w4-read-more {
    display: block;
    margin-top: 13px;
}

.news-list-author,
.schemalogo {
    display: none;
}


@include media-breakpoint-up(md) {
    .w4-news-list {
        .splide__list {
            width: auto;
            display: flex;
            flex-wrap: wrap;
        }

        .splide__arrows {
            display: none;
        }
    }
}

@include media-breakpoint-down(xs) {
    .w4-news-list {
        .splide__slide {
            opacity: 1;
            transition: opacity 0.2s;
        }

        .splide__slide:not(.is-active) {
            opacity: 0.5;
            transition: opacity 0.2s;
        }
    }

    .w4-news-list-detail {
        .splide__slide:not(.is-active) {
            opacity: 1 !important;
        }
    }
}

@include media-breakpoint-down(md) {
    .w4-news-list {
        .splide__track {
            overflow: visible;
        }

        .news-splide {
            width: 100%;
        }

        .splide__arrows {
            padding-left: 12px;
        }

        .splide__arrow {
            display: inline-block;
            align-items: center;
            justify-content: center;
            width: auto;
            border: 0;
            padding: 0;
            background: transparent;
            color: $primary;
            transition: color 0.25s ease-in-out;

            &:disabled {
                .package-svg {
                    svg {
                        stroke: rgba($gray-555, 0.3);
                    }
                }
            }

            .package-svg {
                stroke: $gray-555;
                display: block;
                height: 75px;
                margin-top: -20px;
            }
        }

        .splide__arrow--prev {
            svg {
                transform:scaleX(-1);
            }
        }
    }
}

.w4-news-list-detail {
    .card-group-element {
        width: 100%;
        margin: 0;

        @include media-breakpoint-down(md) {
            .splide__slide {
                margin-bottom: 20px;
            }
        }
    }

    .page-navigation {
        display: block;
        margin: 0 auto;
    }
}
