.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #870337;
  background-color: #fff;
  border: 1px solid #e9e9e9;
}

.page-link:hover {
  z-index: 2;
  color: #3c0119;
  text-decoration: none;
  background-color: #f1f1f1;
  border-color: #e9e9e9;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(135, 3, 55, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #870337;
  border-color: #870337;
}

.page-item.disabled .page-link {
  color: #7c7c7c;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #e9e9e9;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.news .card-group-element {
  margin: -8px;
}

.news-list-view {
  margin-top: 1.5rem;
}

.news-list-view .card-group-element-item .card-body .card-title {
  line-height: 1.3;
}

.news-list-view .card-group-element-item .card-body::before {
  top: 1rem;
}

@media (min-width: 1420px) {
  .news-list-view .card-group-element-item .card-body::before {
    top: 1.3125rem;
  }
}

.w4-news-list a .w4-read-more {
  display: inline-block;
  font-size: 0.938rem;
}

.w4-news-list a .w4-read-more::after {
  content: ' ';
  display: block;
  width: 0;
  border-bottom: 1px solid #870337;
  transition: width 1s;
}

.w4-news-list a:hover .w4-read-more::after {
  width: 100%;
}

.w4-read-more {
  display: block;
  margin-top: 13px;
}

.news-list-author,
.schemalogo {
  display: none;
}

@media (min-width: 768px) {
  .w4-news-list .splide__list {
    width: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .w4-news-list .splide__arrows {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .w4-news-list .splide__slide {
    opacity: 1;
    transition: opacity 0.2s;
  }
  .w4-news-list .splide__slide:not(.is-active) {
    opacity: 0.5;
    transition: opacity 0.2s;
  }
  .w4-news-list-detail .splide__slide:not(.is-active) {
    opacity: 1 !important;
  }
}

@media (max-width: 991.98px) {
  .w4-news-list .splide__track {
    overflow: visible;
  }
  .w4-news-list .news-splide {
    width: 100%;
  }
  .w4-news-list .splide__arrows {
    padding-left: 12px;
  }
  .w4-news-list .splide__arrow {
    display: inline-block;
    align-items: center;
    justify-content: center;
    width: auto;
    border: 0;
    padding: 0;
    background: transparent;
    color: #870337;
    transition: color 0.25s ease-in-out;
  }
  .w4-news-list .splide__arrow:disabled .package-svg svg {
    stroke: rgba(85, 85, 85, 0.3);
  }
  .w4-news-list .splide__arrow .package-svg {
    stroke: #555555;
    display: block;
    height: 75px;
    margin-top: -20px;
  }
  .w4-news-list .splide__arrow--prev svg {
    transform: scaleX(-1);
  }
}

.w4-news-list-detail .card-group-element {
  width: 100%;
  margin: 0;
}

@media (max-width: 991.98px) {
  .w4-news-list-detail .card-group-element .splide__slide {
    margin-bottom: 20px;
  }
}

.w4-news-list-detail .page-navigation {
  display: block;
  margin: 0 auto;
}
